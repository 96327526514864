import Model, { ModelError, TModelError } from "@Models/model";

export enum EBannerSection {
   homepage = "homepage",
   homepageright = "homepageright",

   feedhome = "feedhome", //
   feedhomeleft = "feedhomeleft", //
   feedhomeleftTop = "feedhomeleftTop", //
   feedhomeright = "feedhomeright", //

   shophome = "shophome", // slide
   shophomeright = "shophomeright", // slide
   bestseller = "bestseller", //
   flashsales = "flashsales", //
   newarrival = "newarrival", //
   campaign = "campaign", //
   brandday = "brandday", //
   referral = "referral",

   interiorHome = "interiorhome", //
   interiorhomeright = "interiorhomeright", //
   photoGallery = "photogallery", //
   projectDetail = "projectdetail", //
   design2d3d = "design2d3d", //
   blogdetail = "blogdetail", // list
   partnerlist = "partnerlist", //

   partnerhome = "partnerhome", // https://partner.spacet.vn/
   partnermarketing = "partnermarketing", // https://partner.spacet.vn/marketing-package

   sellerhome = "sellerhome", // list https://spacet.vn/chinh-sach/ban-hang-cung-space-t

   bulkorder = "bulkorder", // list
   affiliateProgram = "affiliateprogram", // list
}
export enum EBannerDevice {
   web = "web",
   app = "app",
   mobileweb = "mobileweb",
}
export type EBannerSize = 64 | 128 | 256 | 384 | 768 | 1024 | 1440;

export interface IBanner extends Model {
   section: EBannerSection;
   rootUrl: string;
   mediaUrl: string;
   cacheUrl: string;
   cacheUrls: any;
   reaction: any;
   position: number;
   meta: {
      title: string;
      description: string;
      altText: string;
   };
}

export default class Banner extends Model<IBanner> implements IBanner {
   public section: EBannerSection;
   public rootUrl: string;
   public mediaUrl: string;
   public cacheUrl: string;
   public cacheUrls: any;
   public reaction: any;
   public position: number;
   public meta: {
      title: string;
      description: string;
      altText: string;
   };

   constructor(data?: IBanner) {
      super(data);
      Object.assign(this, data);
   }

   /**
    * code improve ngày 23 tháng 01 năm 2025
    */
   public static async getBanners({
      sections,
      devicetype,
      width,
   }: {
      sections: EBannerSection[];
      devicetype: EBannerDevice;
      width?: EBannerSize;
   }) {
      try {
         const response = await this.fetch({
            endPoint: "banner",
            method: "GET",
            headers: {
               ...(sections && { sections: sections.join(",") }),
               ...(devicetype && { devicetype: devicetype }),
               ...(width && { width: width.toString() }),
            },
         });

         // Nếu response là ModelError, return ngay hoặc xử lý lỗi
         if (response instanceof ModelError) {
            throw response; // Ném lỗi để SSR hoặc API Routes có thể xử lý đúng cách
         }

         // Kiểm tra response structure trước khi truy cập thuộc tính
         if (!response || typeof response !== "object" || !("data" in response)) {
            throw new ModelError({
               httpCode: 500,
               message: "Invalid response structure.",
            });
         }

         // Trả về dữ liệu đã kiểm tra
         return response.data;
      } catch (error) {
         return new ModelError(error as TModelError);
      }
   }
}
